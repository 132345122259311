/* @import "~@angular/material/prebuilt-themes/indigo-pink.css"; */
@import './theme';
@import './variables';
@import './assets/css/button.scss';

@import "~@ng-select/ng-select/themes/default.theme.css";

//para o calendario
// @import '~@fullcalendar/core/main.css';
// @import '~@fullcalendar/daygrid/main.css';
// @import '~@fullcalendar/list/main.css';

.leaflet-safari .leaflet-tile-container {
  width: 1600px;
  height: 1600px;
  transform-origin: 0 0;
}

.leaflet-overlay-pane svg {
  user-select: none;
}

html {
  position: relative;
  min-height: 100%;
}

html,
body {
  width: 100%;
  height: 100%;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  margin-left: -5px;
  opacity: 0.6;

}

/* Track */
::-webkit-scrollbar-track {
  opacity: 0.6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #555;
  opacity: 0.3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #888;

}

.mdc-dialog__content>:first-child {
  margin-top: 10px !important;
}

section {
  overflow-x: hidden;
  height: 100%;
}

section.sticky-footer {
  margin-bottom: 56px;
}

section.sticky-footer .content-wrapper {
  min-height: calc(100vh - 56px - 56px);
}

body.fixed-nav {
  padding-top: 56px;
}

.content-wrapper {
  min-height: calc(100vh - 56px - 56px);
  // padding-top: 1rem;
  padding-bottom: 56px;
}

.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 3px;
  display: none;
  width: 50px;
  height: 50px;
  text-align: center;
  color: white;
  background: rgba(52, 58, 64, 0.5);
  line-height: 45px;
}

.scroll-to-top:focus,
.scroll-to-top:hover {
  color: white;
}

.scroll-to-top:hover {
  background: #343a40;
}

.scroll-to-top i {
  font-weight: 800;
}

.smaller {
  font-size: 0.7rem;
}

.o-hidden {
  overflow: hidden !important;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}


.card-body-icon {
  position: absolute;
  z-index: 0;
  top: -25px;
  right: -25px;
  font-size: 5rem;
  -webkit-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 1;
  }
}

@media (min-width: 768px) {
  .card-columns {
    column-count: 2;
  }
}

@media (min-width: 1200px) {
  .card-columns {
    column-count: 2;
  }
}

.card-login {
  max-width: 25rem;
}

.card-register {
  max-width: 40rem;
}

footer.sticky-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 56px;
  background-color: #e9ecef;
  line-height: 55px;
}

.table-hover {
  cursor: pointer;
}

.mat-select-value-text {
  color: black
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #f89e32;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #f89e32;
}

.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  background-color: #f89e32;
}

.mat-spinner circle {
  stroke: #f89e32;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #f89e32;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #f89e32;
}

.mat-input-element:disabled {
  color: rgba(0, 0, 0, 0.87) !important
}

.mat-form-field-appearance-outline.mat-form-field-disabled .mat-form-field-label {
  color: rgba(0, 0, 0, 0.65) !important;
}

.example-full-width {
  width: 100% !important;
}

.example-form {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}

input[type="file"] {
  display: none;
}

.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  border-radius: 5px;
  padding: 6px 12px;
  cursor: pointer;
}

.invalid {
  color: red
}

.valid {
  border: 1px solid green
}

.mat-button.mat-primary .mat-button-focus-overlay {
  background-color: transparent !important;
  font-weight: 400 !important;
}

.black-backdrop {
  background-color: #000000;
  opacity: 0.8 !important;
}

.abc::first-letter {
  text-transform: uppercase;
  font-size: 3em;
  font-weight: bold;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  letter-spacing: 5px;
}


.content {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  padding: 10px 5px !important;
  /* max-height: 30px !important; */
  text-overflow: ellipsis !important;
  background-color: var(--primary-color);
  cursor: pointer;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-disabled,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-disabled {
  background: rgb(206, 150, 82) !important;
}

.mat-checkbox-disabled.mat-checkbox-checked .mat-checkbox-background,
.mat-checkbox-disabled.mat-checkbox-indeterminate .mat-checkbox-background {
  background-color: rgb(206, 150, 82) !important;
}



/*  */
.example-container {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.example-is-mobile .example-toolbar {
  position: fixed;
  /* Make sure the toolbar will stay on top of the content as it scrolls past. */
  z-index: 2;
}

h1.example-app-name {
  margin-left: 8px;
}

.example-sidenav-container {
  /* When the sidenav is not fixed, stretch the sidenav container to fill the available space. This
     causes `<mat-sidenav-content>` to act as our scrolling element for desktop layouts. */
  flex: 1;
}

.example-is-mobile .example-sidenav-container {
  /* When the sidenav is fixed, don't constrain the height of the sidenav container. This allows the
     `<body>` to be our scrolling element for mobile layouts. */
  flex: 1 0 auto;
}

.fc-event {
  height: 60px !important;
}

.fc-day-grid-event {
  white-space: normal !important;
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.button--loading .button__text {
  visibility: hidden;
  opacity: 0;
}

.button--loading::after {
  content: "";
  position: absolute;
  width: 40px;
  height: 40px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #000;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

figure.table>table,
figure.table>table>tbody>tr>td {
  border: 1px solid !important;
}
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

/*https://github.com/h2qutc/angular-material-components/issues/348
  fix: ngx-mat-datetime-picker transparent background
*/
.mat-datepicker-content, .time-container {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  display: block;
  border-radius: 4px;
  background-color: var(--mat-datepicker-calendar-container-background-color);
  color: var(--mat-datepicker-calendar-container-text-color);
}

.mat-calendar-body-cell-content {
  color: var(--mat-datepicker-calendar-date-text-color);
  border-color: var(--mat-datepicker-calendar-date-outline-color)
}

.mat-calendar-body-selected {
  background-color: var(--mat-datepicker-calendar-date-selected-state-background-color);
  color: var(--mat-datepicker-calendar-date-selected-state-text-color);
}

.mat-calendar-body-cell-content {
  border: none !important;
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: var(--mat-datepicker-calendar-date-hover-state-background-color);
}

.mat-calendar-arrow {
  fill: var(--mat-datepicker-calendar-period-button-icon-color);
}
a[role="ourRole"] {
  cursor: pointer;
}

.apexcharts-toolbar {
  top: 0px; right: 10px !important;
}