@use "@angular/material" as mat;


// Fonts
// @import 'https://fonts.googleapis.com/icon?family=Material+Icons';
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap');

$fontConfig: (
    headline-1: mat.define-typography-level($font-size: 70px),
    headline-2: mat.define-typography-level($font-size: 44px),
    headline-3: mat.define-typography-level($font-size: 35px),
    headline-4: mat.define-typography-level($font-size: 25px),
    headline-5: mat.define-typography-level($font-size: 20px),
    headline-6: mat.define-typography-level($font-size: 17px),
    subtitle-1: mat.define-typography-level($font-size: 14px),
    subtitle-2: mat.define-typography-level($font-size: 12px),
    body-1: mat.define-typography-level($font-size: 14px),
    body-2: mat.define-typography-level($font-size: 12px),
    button: mat.define-typography-level($font-size: 12px),
    caption: mat.define-typography-level($font-size: 10px)
);

$typography: mat.define-typography-config($fontConfig);


// Light Theme Text
$dark-text: #000000;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
    base: black,
    divider: $dark-dividers,
    dividers: $dark-dividers,
    disabled: $dark-disabled-text,
    disabled-button: rgba($dark-text, 0.26),
    disabled-text: $dark-disabled-text,
    elevation: black,
    secondary-text: $dark-accent-text,
    hint-text: $dark-disabled-text,
    accent-text: $dark-accent-text,
    icon: $dark-accent-text,
    icons: $dark-accent-text,
    text: $dark-primary-text,
    slider-min: $dark-primary-text,
    slider-off: rgba($dark-text, 0.26),
    slider-off-active: $dark-disabled-text
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
    base: $light-text,
    divider: $light-dividers,
    dividers: $light-dividers,
    disabled: $light-disabled-text,
    disabled-button: rgba($light-text, 0.3),
    disabled-text: $light-disabled-text,
    elevation: black,
    hint-text: $light-disabled-text,
    secondary-text: $light-accent-text,
    accent-text: $light-accent-text,
    icon: $light-text,
    icons: $light-text,
    text: $light-text,
    slider-min: $light-text,
    slider-off: rgba($light-text, 0.3),
    slider-off-active: rgba($light-text, 0.3)
);

// Background config
// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#2c2c2c, 0.12);

$mat-light-theme-background: (
    background: $light-background,
    status-bar: $light-bg-darker-20,
    app-bar: $light-bg-darker-5,
    hover: $dark-bg-alpha-4,
    card: $light-bg-lighter-5,
    dialog: $light-bg-lighter-5,
    tooltip: $dark-bg-tooltip,
    disabled-button: $dark-bg-alpha-12,
    raised-button: $light-bg-lighter-5,
    focused-button: $dark-focused,
    selected-button: $light-bg-darker-20,
    selected-disabled-button: $light-bg-darker-30,
    disabled-button-toggle: $light-bg-darker-10,
    unselected-chip: $light-bg-darker-10,
    disabled-list-option: $light-bg-darker-10
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
    background: $dark-background,
    status-bar: $dark-bg-lighter-20,
    app-bar: $dark-bg-lighter-5,
    hover: $light-bg-alpha-4,
    card: $dark-bg-lighter-5,
    dialog: $dark-bg-lighter-5,
    tooltip: $dark-bg-lighter-20,
    disabled-button: $light-bg-alpha-12,
    raised-button: $dark-bg-lighter-5,
    focused-button: $light-focused,
    selected-button: $dark-bg-lighter-20,
    selected-disabled-button: $dark-bg-lighter-30,
    disabled-button-toggle: $dark-bg-lighter-10,
    unselected-chip: $dark-bg-lighter-20,
    disabled-list-option: $dark-bg-lighter-10
);

// Compute font config
@include mat.typography-hierarchy($fontConfig);

// Theme Config

body {
    font-family: "Roboto", sans-serif;
    --primary-color: #4562a3;
    --primary-lighter-color: #bebdce;
    --primary-darker-color: #2b4377;
    --text-primary-color: #{$light-primary-text};
    --text-primary-lighter-color: #{$dark-primary-text};
    --text-primary-darker-color: #{$light-primary-text};
}

$mat-primary: (
    main: #4562a3,
    lighter: #bebdce,
    darker: #2b4377,
    50 : #e9ecf4,
    100 : #c7d0e3,
    200 : #a2b1d1,
    300 : #7d91bf,
    400 : #617ab1,
    500 : #4562a3,
    600 : #3e5a9b,
    700 : #365091,
    800 : #2e4688,
    900 : #1f3477,
    A100 : #b4c5ff,
    A200 : #819dff,
    A400 : #4e75ff,
    A700 : #3561ff,
    contrast: (main: $light-primary-text,
        lighter: $dark-primary-text,
        darker: $light-primary-text,
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
    --accent-color: #f89e32;
    --accent-lighter-color: #e3c49e;
    --accent-darker-color: #e58510;
    --text-accent-color: #{$dark-primary-text};
    --text-accent-lighter-color: #{$dark-primary-text};
    --text-accent-darker-color: #{$dark-primary-text};
}

$mat-accent: (
    main: #f89e32,
    lighter: #e3c49e,
    darker: #e58510,
    50 : #fef3e6,
    100 : #fde2c2,
    200 : #fccf99,
    300 : #fabb70,
    400 : #f9ad51,
    500 : #f89e32,
    600 : #f7962d,
    700 : #f68c26,
    800 : #f5821f,
    900 : #f37013,
    A100 : #ffffff,
    A200 : #fff4ee,
    A400 : #ffd5bb,
    A700 : #ffc5a1,
    contrast: (main: $dark-primary-text,
        lighter: $dark-primary-text,
        darker: $dark-primary-text,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000, )
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);

body {
    --warn-color: #ff0000;
    --warn-lighter-color: #ffb3b3;
    --warn-darker-color: #ff0000;
    --text-warn-color: #{$light-primary-text};
    --text-warn-lighter-color: #{$dark-primary-text};
    --text-warn-darker-color: #{$light-primary-text};
}

$mat-warn: (
    main: #ff0000,
    lighter: #ffb3b3,
    darker: #ff0000,
    200: #ff0000,
    // For slide toggle,
    contrast: (main: $light-primary-text,
        lighter: $dark-primary-text,
        darker: $light-primary-text )
);
$theme-warn: mat.define-palette($mat-warn, main, lighter, darker);

$mat-success: (
    main: #07a130,
    lighter: #6be073,
    darker: #2c781e,
    200: #00ff22,
    // For slide toggle,
    contrast: (main: $light-primary-text,
        lighter: $dark-primary-text,
        darker: $light-primary-text )
);
$theme-success: mat.define-palette($mat-success, main, lighter, darker);

$typography: mat.define-typography-config();
$color: (
    primary: $theme-primary,
    accent: $theme-accent,
    warn: $theme-warn,
    success: $theme-success,
    is-dark: false,
    foreground: $mat-light-theme-foreground,
    background: $mat-light-theme-background
);

$theme: mat.define-light-theme((color: $color,
            typography: $typography,
            density: 0));
// $altTheme: mat.define-light-theme(
//   (
//     color: $color,
//     typography: null,
//     density: null
//   )
// );

// Theme Init
@include mat.all-component-themes($theme);

:root  {
    --primary: #4562a3;
    --secondary: #f89e32;
}

body {
    overflow-y: hidden;
    font-family: "Roboto", sans-serif;
    --primary-color: #4562a3;
    --primary-lighter-color: #bebdce;
    --primary-darker-color: #2b4377;
    --text-primary-color: #{$light-primary-text};
    --text-primary-lighter-color: #{$dark-primary-text};
    --text-primary-darker-color: #{$light-primary-text};
}

.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
    padding: 0 1.15em;
    margin: 0 0.65em;
    min-width: 3em;
    line-height: 36.4px;
}

button .mat-raised-button button{
    font-weight: 400 !important;
}

.mat-button-toggle-checked {
    background-color: mat.get-color-from-palette($theme-primary, "main") !important;
    color: mat.get-color-from-palette($theme-primary, "500-contrast") !important;
    border-color: mat.get-color-from-palette($theme-primary) !important;

    .mat-button-toggle-label-content {
        color: #fff;
    }
}

.mat-toolbar {
    background-color: #343a40 !important;
    color: #e9ecf4;
}

.mat-standard-chip {
    padding: 0.5em 0.85em;
    min-height: 2.5em;
}

.material-icons {
    font-size: 24px;
    font-family: "Material Icons", "Material Icons";
    font-weight: 400 !important;

    .mat-badge-content {
        font-family: "Roboto";
        font-weight: 400 !important;
    }
}

button {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif Im !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.mdc-dialog__content>:first-child {
    margin-top: 10px !important;
}
