
:root {
    --myorange: #fd5f29;
  }
  
.bg-danger{
    background-color: #f89e32 !important;
    border: #f89e32;
    color: white
  }
  
  .bg-primary {
    background-color: #4562a3 !important;
    color: white
  }
  
  a.bg-primary:hover, a.bg-primary:focus,
  button.bg-primary:hover,
  button.bg-primary:focus {
    background-color: #4562a3 !important;
    border:  #4562a3 !important;
    color: white
  }
  
  .btn-primary{
    background: #4562a3;
    border: #4562a3 !important
  }
  
  .btn-primary:hover{
    background: #3e5195;
    border: #3e5195 !important
  }
  
  .btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
  .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color:  #3e5195 !important;
    border-color:  #3e5195 !important
  }
  
  .btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
  .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(62, 80, 147, 0.5);
  }
  
  .btn-danger{
    background: #f89e32;
    border: #f89e32
  }
  
  .btn-danger:hover{
    background: #f58634;
    border: #f58634
  }
  
  .btn-danger:focus {
    box-shadow: 0 0 0 0.2rem #f58634;
    background: #f58634
  }
  
  .btn-danger:focus, .btn-danger.focus {
    box-shadow: 0 0 0 0.2rem #f58634;
  }
  
  .btn-danger.disabled, .btn-danger:disabled {
    color: #fff;
    background-color: #f58634;
    border-color: #f58634;
  }
  
  .btn-danger:not(:disabled):not(.disabled):active, .btn-danger:not(:disabled):not(.disabled).active,
  .show > .btn-danger.dropdown-toggle {
    color: #fff;
    background-color: #f58634;
    border-color: #f58634;
  }
  
  .btn-danger:not(:disabled):not(.disabled):active:focus, .btn-danger:not(:disabled):not(.disabled).active:focus,
  .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem #f58634;
  }
  
  .btn-orange{
    background-color: var(--myorange);
  }

  .bg-orange{
    background-color: var(--myorange);
  }
